.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 6px 60px;
  z-index: 5;
  transition: .3s;
  background-color: transparent;
  
  @include media-breakpoint-down(md) {
    padding: 6px 0px;
  }

  &.opened {
    background-color: white;

    .navbar-brand {
      .light {
        display: none;
      }
      .dark {
        display: block;
      }
    }
  }

  .navbar {
    
    &-brand {
      max-width: 140px;

      img {
        width: 100%;
      }

      .light {
        display: block;
      }
      .dark {
        display: none;
      }

      @include media-breakpoint-down(lg) {
        margin-left: 0;
      }
    }

    &-nav {
      &-item {
        margin-right: 42px;
      }
    }

    &-toggler {
      display: none;

      @include media-breakpoint-down(md) {
        padding: 0;
        display: flex;
        justify-content: space-between;
        width: 30px;
        flex-direction: column;
        position: relative;
        
        &-icon {
          width: 100%;
          height: 4px;
          margin-bottom: 4px;
          transition: .3s all;
          position: relative;
          display: block;
          border-radius: 10px;
          background-color: $black;
        }
        
        
        &.opened {   
          .navbar-toggler-icon {
            display: block;

            &.first {
              transform: rotate(45deg);
              top: 4px;
            }
            &.second {
              display: none;
            }
            &.third {
              transform: rotate(-45deg);
              top: -4px;
            }
          }
        }
      }
    }
  }

  .btn {
    padding: 13px 9px;
    font-size: 13px;
  }
}
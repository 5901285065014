// ############ Fonts ############

@mixin font-face ($name, $file-name, $font-weight, $font-style: normal) {
  @font-face {
      font-family: $name;
      src: url('../fonts/#{$file-name}.woff2') format('woff2'),
          url('../fonts/#{$file-name}.woff') format('woff');
      font-weight: $font-weight;
      font-style: $font-style;
  }
}


$Circular:         'Circular';
@include font-face($Circular, 'CircularStd-Book', 300);
@include font-face($Circular, 'CircularStd-Medium', 400);
@include font-face($Circular, 'CircularStd-Bold', 600);
@include font-face($Circular, 'CircularStd-Black', 700);

$font-primary:       $Circular, sans-serif;
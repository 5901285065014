.hero {
    position: relative;
    padding-top: 66px;
    background: radial-gradient(at 50% 50%, #F7F8FA, #A0B7F3);
    text-align: center;
    overflow: hidden;
    height: 100%;
    background-attachment: fixed;
    background-position: center;
    opacity: 1;
    
    
    .container {
        padding-top: 100px;
    }

    .section-text {
        transition: .1s ease;
        -webkit-transition: .1s ease;
        -moz-transition: .1s ease;
        
        p {
            color: $black-font-100;
        }
    }

    &-light {
        width: 520px;
        height: 520px;
        position: absolute;
        border-radius: 50%;
        touch-action: none;
        background: radial-gradient(ellipse at 20% 50%, #FFF, #A0B7F3);
        opacity: .8;
        filter: blur(100px);
        transform: translate(-50%);
        -webkit-transform: translate(-50%);
        -moz-transform: translate(-50%);

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    &-bcg-blur {
        width: 80vw;
        height: 80vw;
        border-radius: 50%;
        background: radial-gradient(#A1B6EE 10%, rgba(161, 182, 238, 0) 100%);
        filter: blur(40px);
        position: absolute;
        top: 0%;
        overflow: hidden;
        left: 50%;
        transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -webkit-transform: translateX(-50%);

        @include media-breakpoint-down(md) {
            display: none;
        }
    }
    
    &-map {
        position: absolute;
        top: 0px;
        left: 0;
        width: 100%;
        height: 100%;
        transform-style: preserve-3d;
        -moz-transform-style: preserve-3d;
        -webkit-transform-style: preserve-3d;
        transition: .1s;
        -webkit-transition: .1s;
        -moz-transition: .1s;

        @include media-breakpoint-down(md) {
            top: 30%;
        }
        
        img {
            animation: mapVisible 2s ease;
            -webkit-animation: mapVisible 2s ease;
            -moz-animation: mapVisible 2s ease;
            width: 100%;
            height: auto;
            position: relative;
        }
    }

    &-required {
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        display: block;
        color: $blue;
        margin: 15px 0;
    }

    &-pictures {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding-top: 90px;
    
        &-ellipse, &-square {
            position: absolute;
        }
        
        img {
            width: 100%;
            height: auto;
        }

        &-ellipse {
            top: 0px;
            right: 99px;
            width: 280px;
            position: absolute;

            @include media-breakpoint-down(lg) {
                width: 200px;
                right: -80px;
            }
            @include media-breakpoint-down(sm) {
                width: 100px;
                right: -40px;
                top: 40px;
            }
        }

        &-square {
            bottom: 0;
            left: 0;
            width: 250px;

            @include media-breakpoint-down(lg) {
                left: -80px;
                width: 200px;
            }
            @include media-breakpoint-down(sm) {
                width: 170px;
            }
        }

        &-desctop {
            position: relative;
            z-index: 1;
            width: calc(100% - 370px);
            box-shadow: 0px -18px 84px rgba(29, 41, 63, 0.119946);
            border-radius: 10px 10px 0px 0px;
            overflow: hidden;
            
            @include media-breakpoint-down(lg) {
                width: auto;
            }
        }

    }
}

@keyframes mapVisible {
    0% {
        width: 30%;
    }
    100% {
        width: 100%;
    }
}
.partners {
    margin-top: 54px;

    @include media-breakpoint-down(sm) {
        margin-top: 120px;
    }

    &-items {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: calc(100% - 160px);
        margin: 0 auto;
        width: calc(100% - 370px);
        
        @include media-breakpoint-down(lg) {
            width: auto;
        }
        
        @include media-breakpoint-down(sm) {
            flex-direction: column;
        }
    }
    
    &-item {
        height: 26px;
        width: auto;
        transition: .3s;
        filter: grayscale(100%);
        cursor: pointer;
        opacity: 0.5;
        
        &:hover {
            opacity: 1;
            filter: grayscale(0%);
        }

        @include media-breakpoint-down(sm) {
            margin-bottom: 80px;
            height: auto;
        }
        
        img {
            height: 100%;
            width: auto;
            
            @include media-breakpoint-down(sm) {
                width: 100%;
                height: auto;
            }
        }
    }
}
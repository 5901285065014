// ############ Colors ############

$white:             #ffffff;
$white-font:        #F8F9FF;
$white-font-100:    #E9F7FE;

$black:             #1B1A35;  
$black-font-100:    #1D293F;

$blue:              #0F53FA;
$blue-bcg:          #388FF6;
$blue-font:         #243F94;

$gray-bcg:          #F7F8FA;
$gray-border:       #E2E4E8;
$gray-line:         #ECEFF4;
$gray-font-100:     #7C8087;
$cyan:              #21C6DD;
$yellow-bcg:        #FCD066;
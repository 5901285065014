.solutions {
    position: relative;
    margin-top: 120px;
    padding-bottom: 130px;
    border-bottom: 2px solid $gray-line;
    
    &-slider {
        align-items: stretch;
        margin-top: 64px;
        right: -50%;
        height: auto;
        position: relative;
        transition: 1.5s;
        opacity: 0;

        @include media-breakpoint-down(sm) {
            padding: 0 24px;
        }
        
        &.visible {
            opacity: 1;
            right: 0;
        }

        &-wrap {
            position: relative;
        }
        
        &-bcg {
            position: absolute;
            width: 100%;
            max-width: 350px;
            height: 100%;
            background-image: linear-gradient(to left, rgba(255, 255, 255, 0.8),rgba(255, 255, 255, 0.01));
            z-index: 1;
            touch-action: none;
            pointer-events: none;
            top: 0;
            right: 0;
            
            &:nth-child(1) {
                background-image: linear-gradient(to right, rgba(255, 255, 255, 0.8),rgba(255, 255, 255, 0.01));
                left: 0;
            }

            @include media-breakpoint-down(md) {
                max-width: 150px;
            }
            @include media-breakpoint-down(sm) {
                max-width: 50px;
            }
        }
        
        &-item {
            max-width: 350px;
            width: 100%;
            background-color: $gray-bcg;
            border-radius: 10px;
            padding: 16px;
            height: auto;
            display: flex;
            position: relative;
            justify-content: flex-start;
            align-items: flex-start;
            overflow: hidden;
            outline: none;
            margin: 0 15px;
            cursor: pointer;

            --x: calc(100% + 30px);
            --y: calc(100% + 30px);
            --w: calc(100% * 2);

            &:hover {
    
                &::after {
                  width: var(--w);
                  height: var(--w);
                  border: 13px solid $blue;
    
                  @include media-breakpoint-down(md) {
                    width: 180vw;
                    height: 180vw;
                    left: 80%;
                    top: 100%;
                  }
                }

            }
            
            &::after {
                content: "";
                position: absolute;
                
                left: var(--x);
                top: var(--y);
                width: 0px;
                height: 0px;
                background-color: $yellow-bcg;
                border-radius: 50%;
                outline: none;
                border: none;
                transform: translateX(-50%) translateY(-50%);
                -moz-transform: translateX(-50%) translateY(-50%);
                -ms-transform: translateX(-50%) translateY(-50%);
                -o-transform: translateX(-50%) translateY(-50%);
                
                transition: width 0.7s, height 0.7s;

                // @include media-breakpoint-down(md) {
                //     display: none;
                // }
            }
            
            &-img {
                width: 55px;
                height: 55px;
                min-width: 55px;
                background-color: white;
                border-radius: 50%;
                z-index: 1;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
                
                img {
                    width: 21px;
                }
            }
            
            &-text {
                position: relative;
                margin-left: 20px;
                margin-top: 4px;
                z-index: 1;
                
                h3 {
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 24px;
                }
                
                p {
                    color: $gray-font-100;
                    font-size: 16px;
                    line-height: 28px;
                }
            }
        }
    }
    
    .slick { 
        &-initialized .slick-slide {
            display: flex;
        }
        
        &-track {
            display: flex;
        }
    }

    .btn {
        margin: 60px auto 0px;
        img {
            width: 20px;
            height: auto;
            margin-right: 15px;
        }
    }
}
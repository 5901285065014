/** Buttons **/

a {
  display: inline-flex;
  text-decoration: none;
  transition: all .3s ease;
  outline: none;
  
  &:hover {
    text-decoration: none;
  }
}

button {
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  width: auto;
  box-sizing: border-box;
  cursor: pointer;
  transition: all .3s ease;

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.btn {
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px 16px;
  color: $white;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  cursor: pointer;
  overflow: hidden;
  border: none;
  position: relative;
  outline: none;
  box-sizing: border-box;

  --x: calc(100% + 30px);
  --y: calc(100% + 30px);

  --w: calc(100% * 2);

  &:hover {
    &::after {
      width: var(--w);
      height: var(--w);

    }

    .btn-icon {
      display: none;
      
      &.hover {
        display: block;
      }
    }
  }


  
  &::after {
    content: "";
    position: absolute;
    left: var(--x);
    top: var(--y);
    width: 0px;
    height: 0px;
    border-radius: 50%;
    
    background: white;
    transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transition: width 0.7s, height 0.7s;
  }

  
  &:active {
    opacity: 0.5;

    @include media-breakpoint-down(md) {
      &::after {
        width: var(--w);
        height: var(--w);
        top: 80%;
        left: 80%;
      }
    }
  }

  &-text {
    margin-left: 27px;
    text-align: left;
    z-index: 1;
    
    span {
      font-size: 16px;
      font-weight: 100;
    }
    
    h4 {
      font-size: 24px;
      font-weight: 700;
      margin-top: 5px;
      white-space: nowrap;
    }
  }
  
  span {
    z-index: 1;
    font-size: 13px;
    line-height: 20px;
    font-weight: 500;
    color: white;
  }

  img {
    height: 32px;
  }

  &-icon {
    z-index: 1;
    display: block;

    &.hover {
      display: none;
    }
  }
  
  &-primary {
    background-color: $blue;
    border: 1px solid $blue;

    .btn-text {
      span, h4 {
        color: white;
      }
    }

    &:hover {
       span {
        color: $blue;
      }

      @include media-breakpoint-down(md) {
        background-color: white;
      }

      .btn-text {
        span, h4 {
          color: black;
        }
      }
    }

    &-light {
      background-color: white;
      border: 1px solid $gray-border;
      color: $blue;

      &::after {
        background: $blue;
      }

      span {
        color: $gray-font-100;
      }
      h4 {
        color: black;
      }

      &:hover {

        @include media-breakpoint-down (md) {
          background-color: $blue;
        }

        span {
          color: $white;
        }
        
        .btn-text {
          span, h4 {
            color: $white;
          }
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}
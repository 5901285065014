.footer {
  position: relative;
  padding-top: 137px;
  width: 100%;
  overflow: hidden;
  bottom: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: $black-font-100;
  
  &-logo {
    position: relative;
    margin-bottom: 17px;
  }

  &-text {
    max-width: 266px;

    @include media-breakpoint-down(md) {
      margin-bottom: 70px;
      max-width: 80%;
    }
    
    .btn {
      max-width: 125px;
      font-size: 13px;
      padding: 8px 20px;
      margin-top: 28px;

      span {
        font-size: 13px;
        font-weight: 400;
        color: white;
      }

      &:hover {
        border: 1px solid #fff;
        
        span {
          color: $blue;
        }
      }
    }
  }

  span {
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    color: $gray-font-100;
  }

  p {
    font-weight: 400;
    color: $white-font-100;
    line-height: 42px;
    font-size: 16px;
    margin-bottom: 0;
  }

  &-menu {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    // max-width: 255px;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }

    &-col {
      position: relative;
      margin-right: 33px;
      min-width: 150px;
      max-width: 255px;

      p {
        margin-bottom: 16px;
      }

      span {
        margin-bottom: 16px;
        display: block;
        position: relative;
      }
    }

    &-contacts {
      p {
        margin-bottom: 0;
        color: $yellow;
      }
    }
  }

  &-copyright {
    margin-top: 118px;
    padding: 27px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #313D55;
    position: relative;
    z-index: 2;

    &-social {
      a {
        margin-left: 18px;
      }
      img {
        height: 16px;
      }
    }
  }

  &-ellipse {
    width: 255px;
    position: absolute;
    bottom: -127px;
    left: -127px;
  }
}

.get {
    padding: 318px 0px;
    position: relative;
    opacity: 0;
    transition: 1s;

    &.visible {
        opacity: 1;
        padding: 118px 0px;

        @include media-breakpoint-down(md) {
            padding: 0;
        }

        .get-bcg {
            &-ellipse {
                right: 50px;
                

                @include media-breakpoint-down(md) {
                    right: -50px;
                }
                @include media-breakpoint-down (sm) {
                    right: 50%;
                    transform: translateX(50%);
                }
            }
        }
    }

    &-text {
        p {
            margin: 25px 0 35px;
            font-size: 18px;
            line-height: 32px;
            color: $gray-font-100;
        }
    }

    &-list {
        position: relative;

        li {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 16px;
        }

        &-img {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $gray-bcg;
            margin-right: 18px;

            @include media-breakpoint-down(sm) {
                width: 50px;
                height: 50px;
            }
        }

        h3 {
            font-size: 24px;
            line-height: 34px;
            display: flex;
            align-items: center;

            @include media-breakpoint-down(sm) {
                font-size: 20px;
            }

            span {
                text-transform: uppercase;
                font-size: 13px;
                font-weight: 700;
                line-height: 17px;
                margin-left: 13px;
                padding: 5px 7px;
                border-radius: 5px;
                background-color: $yellow;
            }
        }
    }

    .col-lg-7 {
        @include media-breakpoint-down(md) {
            order: -1;
        }
    }

    &-bcg {
        width: 100%;
        height: 100%;
        position: relative;
        right: -80px;
        transition: transform .1s ease;
        transform-style: preserve-3d;
        transform: translateX(0deg) translateY(0deg);

        @include media-breakpoint-down(md) {
            width: 100%;
            height: auto;
            right: 0;
            margin: 118px 0px;
        }

        &:hover {
            .get-bcg {
                &-desctop {
                    transform: translateY(-50%) translate3d(0px, 0px, 60px);
                }
                &-bitmap {
                    transform: rotate(-9deg) translate3d(0px, 0px, 120px);
                }
            }
        }
        
        &-ellipse {
            position: relative;
            right: -500px;
            transition: .2s;
            z-index: 1;
            transform: translate3d(0px);
            
            @include media-breakpoint-down(sm) {
                width: 100%;
                right: -100px;
            }
        }
        
        &-desctop {
            position: absolute;
            height: 100%;
            top: 56%;
            transition: .4s;
            max-height: 600px;
            border-radius: 10px;
            transform: translateY(-50%) translate3d(0px, 0px, 0px);
            -webkit-box-shadow: -1px 1px 29px 12px rgba(34, 60, 80, 0.2);
            -moz-box-shadow: -1px 1px 29px 12px rgba(34, 60, 80, 0.2);
            box-shadow: -1px 1px 25px 8px rgba(34, 60, 80, 0.2);
            right: 0%;
            z-index: 2;
            
            @include media-breakpoint-down(lg) {
                height: 80%;
            }
           
            @include media-breakpoint-down(sm) {
                width: 80%;
                height: auto;
            }
        }
        
        &-bitmap {
            position: absolute;
            top: 23%;
            max-width: 330px;
            left: -7%;
            transition: .6s;
            transform: rotate(-9deg) translate3d(0px, 0px, 20px);
            z-index: 3;

            @include media-breakpoint-down(lg) {
                left: -23%;
            }

            @include media-breakpoint-down(md) {
                width: 40%;
                left: 20%;
            }

            @include media-breakpoint-down(sm) {
                left: 0%;
            }
        }
    }
}
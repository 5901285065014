.choose-plan {
  position: relative;
  padding-top: 137px;
  padding-bottom: 120px;

  &-head {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }

    h2 {
      max-width: 440px;
    }

    &-tarif {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      background-color: $yellow;
      border-radius: 10px;
      animation: .5s all;

      &:active {
        animation-name: tarifSelect;
      }
      
      @include media-breakpoint-down(md) {
        margin-top: 60px;
      }
      
      @include media-breakpoint-down(sm) {
        width: 80%;
      }


      &-bcg {
        position: absolute;
        height: calc(100% - 8px);
        width: calc(50% - 4px);
        left: 4px;
        background-color: white;
        transition: .2s;

        &.yearly {
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
        }
        
        &.monthly {
          left: 50%;
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }
      }

      &-item {
        min-width: 170px;
        padding: 18px;
        cursor: pointer;
        font-size: 16px;
        font-weight: 500;
        color: $black;
        margin: 0 auto;
        display: block;
        z-index: 2;
        text-align: center;
        transform: scale(1);

        @include media-breakpoint-down(sm) {
          min-width: auto;
        }
      }
    }
  }

  &-table {
    display: none;
    flex-direction: column;
    margin-top: 50px;
    padding-bottom: 36px;
    border-top: 1px solid $gray-line;
    position: relative;
    z-index: 2;

    &.current {
      display: flex;
    }

    &-item {
      padding: 30px 0px;
      border-bottom: 1px solid $gray-line;
      position: relative;
      overflow: hidden;
      transition: .4s;

      --x: 0px;
      --y: 0px;
      --w: 0px;

      &:hover {
        padding: 30px;
        color: white;
        
        @include media-breakpoint-down(md) {
          background-color: $blue;
        }

        .choose-plan-table-item {
          &-type, &-storage p, &-coast h3, &-coast p {
            color: white;
          }
        }

        &::after {
          width: var(--w);
          height: var(--w);
          z-index: -1;

          @include media-breakpoint-down(sm) {
            width: calc(var(--w) * 2);
            height: calc(var(--w) * 2);
          }
        }

        .btn {
          background-color: white;

          span {
            color: $blue;
          }
        }
      }

      &::after {
        content: "";
        width: 0px;
        height: 0px;
        background-color: $blue;
        position: absolute;
        top: var(--y);
        left: var(--x);
        border-radius: 50%;
        transform: translateX(-50%) translateY(-50%);
        -moz-transform: translateX(-50%) translateY(-50%);
        -ms-transform: translateX(-50%) translateY(-50%);
        -o-transform: translateX(-50%) translateY(-50%);
        transition: width .8s, height .8s;
      }

      @include media-breakpoint-down(md) {
        text-align: center;
      }

      &:last-child {
        border-bottom: none;
      }
      
      &-type {
        font-size: 21px;
        font-weight: 700;
        line-height: 34px;
      }

      &-storage {
        p {
          line-height: 40px;
          font-size: 18px;
          font-weight: 400;
          margin-bottom: 0;
          color: $black;
        }

        @include media-breakpoint-down(md) {
          margin-top: 32px;
        }
      }

      &-coast {
        h3 {
          font-size: 58px;
          font-weight: 500;
          line-height: 64px;
        }

        p {
          font-size: 16px;
          line-height: 28px;
          font-weight: 400;
          color: $gray-font-100;
          margin-bottom: 0;
        }

        @include media-breakpoint-down(md) {
          margin-top: 32px;
        }
      }

      &-trial {
        @include media-breakpoint-down(md) {
          margin-top: 32px;
        }
      }
    }
  }

  &-square {
    position: absolute;
    bottom: -27%;
    right: 0;
    z-index: 1;
  }
}

@keyframes tarifSelect {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
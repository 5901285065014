*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  height: auto;
  width: 100%;
  overflow-x: hidden;
}

body {
  height: 100%;
  font-family: $font-primary;
  background-color: $white;
  overflow-x: hidden;
}

.container {
  padding-left: 72px;
  padding-right: 72px;

  @include media-breakpoint-down(md) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.section-text {
  max-width: 560px;
  display: block;
  margin: 0 auto;
  text-align: center;
  position: relative;

  p {
    margin: 22px 0 38px;
    color: $gray-font-100;
  }

  button {
    margin: 0 auto;
  }
}

p {
  color: $gray-font-100;
  font-size: 21px;
  font-weight: 400;
  line-height: 30px;

  @include media-breakpoint-down(md) {
    font-size: 16px;
    line-height: 26px;
  }
}

a {
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  color: $black-font-100;
}

h1, h1, h3, h4, h5, h5 {
  margin: 0;
  color: $black-font-100;
}

h1 {
  font-weight: 700;
  font-size: 58px;
  line-height: 64px;
  color: $black-font-100;

  @include media-breakpoint-down(sm) {
    font-size: 36px;
    line-height: 48px;
  }
}

h2 {
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  color: $black-font-100;

  @include media-breakpoint-down(md) {
    font-size: 36px;
    line-height: 41px;
  }
}


ul {
  margin: 0;
  padding: 0;
}

img {
  pointer-events: none;
  height: auto;
}

// .modal {

//   &-content {
//     border: none;
//     min-width: 526px;
//     border-radius: 40px;
//     padding: 0 50px;

//     @include media-breakpoint-down(sm) {
//       min-width: 100%;
//       padding: 0 16px;
//     }
//   }

//   &-header {
//     justify-content: center;
//     border: none;

//     .close {
//       margin: 0;
//       font-size: 50px;
//       position: absolute;
//       top: 14px;
//       right: 30px;
//       padding: 0;
//     }
//   }
//   &-title {
//     text-align: center;
//     margin-top: 40px;
//   }

//   &-body {
    
//     form {

//       label {
//         color: $blue-font;
//         font-weight: 500;
//         font-size: 14px;
//         line-height: 17px;
//       }

//       input, textarea {
//         background: #F4F7FF;
//         border-radius: 8px;  
//         color: $black;
//         padding: 31px 16px;
//         font-weight: 400;
//         font-size: 16px;
//         margin-bottom: 24px;
//         border: none;
        
//         &::placeholder {
//           color: $gray;
//         }
//       }
//     }
//   }

//   &-footer {
//     border: none;

//     .button {
//       margin: 0 auto;
//       padding: 0px 186px;
      
//       @include media-breakpoint-down(sm) {
//         padding: 0;
//         width: 100%;
//       }
//     }
//   }
// }
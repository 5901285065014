.work {
    padding: 118px 0px;

    &-bcg {
        width: 100%;
        height: 100%;
        position: relative;
        left: -80px;
        transition: .2s;
        transform-style: preserve-3d;
        transform: translateX(0deg) translateY(0deg);

        @include media-breakpoint-down(md) {
            margin-bottom: 140px;
            left: 0;
        }
        
        @include media-breakpoint-down(sm) {
            margin-bottom: 100px;
        }

        &:hover {
            .work-bcg {
                &-app {
                    transform: translateX(-50%) rotate(6deg) translate3d(0px, 0px, 80px);
                }
                &-bitmap {
                    transform: rotate(-4deg) translate3d(0px, 0px, 140px);
                }
            }
        }
        
        &-ellipse {
            position: relative;
            left: 80px;
            transition: .2s;

            @include media-breakpoint-down(md) {
                left: 50%;
                transform: translateX(-50%);
                width: 80%;
            }
        }

        &-app {
            position: absolute;
            top: -100px;
            left: calc(50% - 40px);
            transition: .4s;
            transform: translateX(-50%) rotate(6deg);

            @include media-breakpoint-down(lg) {
                left: 50%;
            }

            @include media-breakpoint-down(md) {
                left: 50%;
                top: -70px;
            }

            @include media-breakpoint-down(sm) {
                width: 80%;
            }
        }

        &-bitmap {
            position: absolute;
            top: 53%;
            right: 0%;
            transition: .5s;
            transform: rotate(-4deg) translate3d(0px, 0px, 20px);

            @include media-breakpoint-down(lg) {
                right: -27%;
            }

            @include media-breakpoint-down(md) {
                width: 58%;
                top: 46%;
                right: 0%;
            }
        }
    }


    &-text {
        p {
            margin: 25px 0 35px;
            font-size: 18px;
            line-height: 32px;
            color: $gray-font-100;
        }
    }

    &-btns {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        max-width: 240px;

        .btn {
            padding: 10px 27px;
            margin-bottom: 15px;
            justify-content: flex-start;

            @include media-breakpoint-down(sm) {
                justify-content: center;
            }
        }

        @include media-breakpoint-down(sm) {
            max-width: 100%;
        }
    }
}
.peoples {
    position: relative;
    background-color: $gray-bcg;
    margin-top: 140px;
    padding: 120px 0px;
    z-index: 1;

    h2 {
        text-align: center;
    }

    .section-text span {
        color: $yellow;
    }

    &-card {
        margin-bottom: 32px; 
        cursor: pointer;
    }
    
    &-cards {
        margin-top: 72px;

        &-item {
            border-radius: 10px;
            background-color: white;
            border: 1px solid $gray-border;
            position: relative;
            padding: 20px;
            height: 100%;
            overflow: hidden;

            &-head-text span, &-text p {
                color: $gray-font-100;
                animation: hideText .4s;
            }
            
            &:hover {
                position: relative;
                z-index: 2;
                animation: cardBcg .5s;
                background-color: $blue-bcg;

                .peoples-cards-item-head-twitter {
                    .light {
                        display: flex;
                        z-index: 3;
                        position: absolute;
                        top: 0;
                        right: 0px;
                    }
                    .green {
                        transform: scale(80);
                        transition: 1s;
                    }
                }

                .peoples-cards-item  {
                    &-head-text h3, &-head-text span, &-text p {
                        color: white;
                        animation: showText .4s;
                    }
                }
            }

            &-head {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                position: relative;
                margin-bottom: 18px;

                &-avatar {
                    margin-right: 13px;
                    width: 41px;
                    height: 41px;
                    border-radius: 50%;
                    overflow: hidden;
                    z-index: 1;
                    position: relative;
                    
                    img {
                        height: 100%;
                        position: relative;
                        width: auto;
                    }
                }
                
                &-text {
                    z-index: 1;
                    position: relative;

                    h3 {
                        font-size: 18px;
                        position: relative;
                        font-weight: 500;
                    }
                    span {
                        position: relative;
                        font-weight: 400;
                        font-size: 13px;
                        color: $gray-font-100;
                    }
                }
                
                &-twitter {
                    position: absolute;
                    right: 0px;
                    top: 0px;
                    
                    .light {
                        display: none;
                    }

                    .green {
                        transition: .5s;
                    }
                }
            }

            &-text {
                position: relative;
                p {
                    font-size: 16px;
                    color: $gray-font-100;
                    line-height: 28px;
                }
            }

        }
    }
}

@keyframes twitter {
    60% {
        z-index: 2;
    }
    100% {
        transform: scale(80);
    }
}

@keyframes cardBcg {
    0% {
        background-color: white;
    }
    90% {
        background-color: white;
    }
    100% {
        background-color: $blue-bcg;
    }
}

@keyframes showText {
    0% {
        color: $gray-font-100;
    }
    100% {
        color: white;
    }
}

@keyframes hideText {
    0% {
        color: white;
        opacity: 0;
    }
    50% {
        opacity: 0;
        color: white;   
    }
    100% {
        opacity: 1;
        color: $gray-font-100;
    }
}